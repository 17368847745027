<template>

    <div>
  
      <gasto-add-new
        :is-add-new-gasto-sidebar-active.sync="isAddNewGastoSidebarActive"
        @refetch-data="refetchData"
      />
  
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
  
        <div class="m-2">
  
          <!-- Table Top -->
          <b-row>
  
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>registros</label>
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Buscar..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewGastoSidebarActive = true"
                >
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon"/> Registrar Concepto de Gasto</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
  
        </div>
  
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUsers"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No se encontraron registros"
          :sort-desc.sync="isSortDirDesc"
        >
  
          <!-- Column: User -->
          <template #cell(nombre)="data">
            <b-media vertical-align="center">
             
              <b-link
                :to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.nombre }}
              </b-link>
              <small class="text-muted">@{{ data.item.descripcion }}</small>
            </b-media>
          </template>
  
          <template #cell(pago_por_hora)="data">
                      <span class="align-text-top">{{ formatMoney(data.item.pago_por_hora) }}</span>
                </template>
  
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
  
           <!-- Column: Actions -->
           <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
            >
  
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }">
                <feather-icon icon="FileTextIcon" class="text-info"/>
                <span class="align-middle ml-50 text-info">Detalles</span>
              </b-dropdown-item>
  
              <b-dropdown-item :to="{ /*name: 'apps-users-edit', params: { id: data.item.id }*/ }">
                <feather-icon icon="EditIcon" class="text-warning"/>
                <span class="align-middle ml-50 text-warning">Editar</span>
              </b-dropdown-item>
  
              <b-dropdown-item @click="deleteMalla(data.item)">
                <feather-icon icon="TrashIcon" class="text-danger"/>
                <span class="align-middle ml-50 text-danger">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
  
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import useMallaList from './useGastosList'
  import gastoStoreModule from './gastosStoreModule'
  import GastoAddNew from './GastoAddNew.vue'
  
  export default {
    components: {
      GastoAddNew,
  
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
  
      vSelect,
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = 'app-cat-gastos'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, gastoStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
      const isAddNewGastoSidebarActive = ref(false)
  
      const {
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        deleteMalla,
        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
  
      } = useMallaList()
  
      return {
  
        // Sidebar
        isAddNewGastoSidebarActive,
  
        fetchUsers,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        deleteMalla,
        // Filter
        avatarText,
  
        // UI
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
      }
    },
    methods: {
      formatMoney(value) {
                  //format to mexican currency
          var formatter = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2
          });
  
          return formatter.format(value);
              },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  